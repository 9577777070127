import Link from 'next/link'
import { Box, Flex, Text } from "@chakra-ui/react"


export default function PageNotFound() {
  return (
    <Box>
      <Flex align="center" px='6.5rem' justify='center' flexDirection='column'>

        <Text>404 - Page Not Found</Text>
        <div>
          <Link href="/" scroll={false}>
            <a>
              Go back home
            </a>
          </Link>
        </div>
      </Flex>
    </Box>
  )
}
